import React from "react";
import Logo from "../assets/logo/ComparisonLogo.svg";

const ComparisonTable = () => {
  return (
    <>
      <div className="w-full self-center rounded-[16px] border-[1px] border-[#E8E8E8] bg-white pt-5 md:max-w-[664px] lg:hidden lg:max-w-[1120px]">
        <div className="flex items-start space-x-5 border-b-[1px] border-[#E8E8E8] bg-[#E9FAF0] py-5 pr-[10px] pl-5">
          <div className="w-[144px]">
            <img src={Logo} alt="Vloto" className="" />
          </div>

          <div className="flex flex-col   ">
            <p className="mb-1 text-[#757474]">Start (gratis)</p>
            <p className="text-[#757474]">€5,- per uur</p>
            <p className="text-[#757474]">€0,35 per km</p>
          </div>
        </div>

        <div className="text-18px] flex space-x-5 border-b-[1px] border-[#E8E8E8] py-5 pr-[10px] pl-5 leading-[28px]">
          <p className="w-[144px]">MyWheels</p>

          <div className="flex flex-col  ">
            <p className="mb-1 text-[#757474]">Start (gratis)</p>
            <p className="text-[#757474]">€4,95 per uur</p>
            <p className="text-[#757474]">€0,46 per km</p>
          </div>
        </div>

        <div className="text-18px] flex space-x-5 py-5 pr-[10px] pl-5 leading-[28px]">
          <p className="w-[144px]">Greenwheels</p>

          <div className="flex flex-col  ">
            <p className="mb-1 text-[#757474]">Soms (gratis)</p>
            <p className="text-[#757474]">€3,90 per uur</p>
            <p className="text-[#757474]">€0,42 per km</p>
          </div>
        </div>
      </div>

      <div className="hidden overflow-hidden rounded-[30px] border-[1px] border-[#E8E8E8] bg-white lg:flex lg:w-[1120px] lg:flex-col">
        <div className="">
          <div className="flex w-full space-x-[46px] border-b-[1px] border-[#E8E8E8] bg-white py-5">
            <p className=" w-[202px]"></p>
            <p className="w-[220px] text-[20px] font-medium leading-[32px] text-[#1C1C1C]">
              Abonnementstype
            </p>
            <p className="w-[220px] text-[20px] font-medium leading-[32px] text-[#1C1C1C]">
              Uurtarief
            </p>
            <p className="w-[220px] text-[20px] font-medium leading-[32px] text-[#1C1C1C]">
              Kilometertarief
            </p>
          </div>
        </div>

        <div className="">
          <div className="flex w-full items-center space-x-[46px] border-b-[1px] border-[#E8E8E8] bg-[#E9FAF0] py-[18px]">
            <div className="w-[202px]">
              <img src={Logo} alt="Vloto" className="ml-[60px]" />
            </div>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              Pro
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €2,50 per uur
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €0,26 per km
            </p>
          </div>
        </div>

        <div className="">
          <div className="flex w-full items-center space-x-[46px] border-b-[1px] border-[#E8E8E8] bg-white py-[18px]">
            <p className="w-[202px] pl-[60px] text-[20px] leading-[32px] text-[#1c1c1c]">
              MyWheels
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              Pro
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €3,71 per uur
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €0,35 per km
            </p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex w-full items-center space-x-[46px]   bg-white py-[18px]">
            <p className="w-[202px] pl-[60px] text-[20px] leading-[32px] text-[#1c1c1c]">
              Greenwheels
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              Vaak
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €2,30 per uur
            </p>
            <p className="w-[220px] text-[18px] font-medium leading-[28px] text-[#757474]">
              €0,30 per km
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonTable;
