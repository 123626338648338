export const getRecommendedPlan = (hasDiscount, km, hr) => {
  const subscriptions = [];

  const introPrice = calculateIntroCosts(hasDiscount, km, hr);
  const basisPrice = calculateBasisCosts(hasDiscount, km, hr);

  const plusPrice = calculatePlusCosts(hasDiscount, km, hr);
  const proPrice = calculateProCosts(hasDiscount, km, hr);

  subscriptions.push(introPrice, basisPrice, plusPrice, proPrice);

  const cheapestSubscription = subscriptions.reduce((prev, curr) =>
    prev.price < curr.price ? prev : curr
  );

  return cheapestSubscription;
};

const calculateIntroCosts = (hasDiscount, km, hr) => {
  const costsPerKm = 0.35;
  const costsPerHour = hasDiscount ? 2.5 : 5;

  const totalKmPrice = km * costsPerKm;
  const totalHourlyPrice = hr * costsPerHour;

  const totalPrice = totalKmPrice + totalHourlyPrice;

  return { price: totalPrice, type: "intro" };
};

const calculateBasisCosts = (hasDiscount, km, hr) => {
  const costsPerKm = 0.32;
  const costsPerHour = 4.5;
  const freeHours = 10;
  const pricePerMonth = 50;

  let paidHours = 0;

  if (hr > freeHours) {
    paidHours = hr - freeHours;
  }

  const totalKmPrice = km * costsPerKm;
  const totalHourlyPrice = paidHours * costsPerHour;
  const totalPrice = totalKmPrice + totalHourlyPrice + pricePerMonth;

  return { price: totalPrice, type: "basis" };
};

const calculatePlusCosts = (hasDiscount, km, hr) => {
  const costsPerKm = 0.29;
  const costsPerHour = hasDiscount ? 1.5 : 3;
  const freeHours = 30;
  const pricePerMonth = hasDiscount ? 50 : 100;

  let paidHours = 0;

  if (hr > freeHours) {
    paidHours = hr - freeHours;
  }

  const totalKmPrice = km * costsPerKm;
  const totalHourlyPrice = paidHours * costsPerHour;
  const totalPrice = totalKmPrice + totalHourlyPrice + pricePerMonth;

  return { price: totalPrice, type: "plus" };
};

const calculateProCosts = (hasDiscount, km, hr) => {
  const costsPerKm = 0.26;
  const costsPerHour = hasDiscount ? 1.25 : 2.5;
  const freeHours = 80;
  const pricePerMonth = hasDiscount ? 100 : 200;

  let paidHours = 0;

  if (hr > freeHours) {
    paidHours = hr - freeHours;
  }

  const totalKmPrice = km * costsPerKm;
  const totalHourlyPrice = paidHours * costsPerHour;
  const totalPrice = totalKmPrice + totalHourlyPrice + pricePerMonth;

  return { price: totalPrice, type: "pro" };
};

export const getPriceOwningCar = (userKm) => {
  if (userKm >= 500 && userKm < 750) {
    return 400;
  } else if (userKm >= 750 && userKm < 1000) {
    return 458;
  } else if (userKm >= 1000 && userKm < 1250) {
    return 508;
  } else if (userKm >= 1250 && userKm < 1500) {
    return 566;
  } else if (userKm >= 1500 && userKm < 1750) {
    return 619;
  } else if (userKm >= 1750 && userKm < 2000) {
    return 668;
  } else if (userKm >= 2000 && userKm < 2250) {
    return 715;
  } else if (userKm >= 2250 && userKm < 2500) {
    return 773;
  } else if (userKm >= 2500) {
    return 823;
  } else {
    return 250;
  }
};

export const userFeedback = [
  { name: "Jesse M.", feedback: "Goed 🤩", type: "short" },
  {
    name: "Dylon en Lisanne",
    feedback: `Wat een goed nieuws! Wij zijn momenteel erg blij met het vrij parkeren met jullie Vloto auto's gezien werk en familie en zullen hier veelvuldig gebruik van maken.`,
  },
  { name: "Wilco D.", feedback: "Toprit 😍", type: "short" },
  {
    name: "Emrah",
    feedback:
      "Hi, Het is een leuk concept dat jullie aanbieden en met mooie auto's ook. Nemen we ook mee in de keuze bij het aanschaffen van een woning in de Krijgsman.",
  },
  { name: "Chantal D.", feedback: "Heel fijn 🤩", type: "short" },
  {
    name: "Elric C.",
    feedback: "Heerlijk en makkelijk! 💚",
    type: "short",
  },
  {
    name: "Lucas",
    feedback:
      "Wat een geweldige toevoeging aan jullie dienst. Ik doe mijn Lynk & Co de deur uit en zal vanaf 1 juli weer een abonnement nemen.",
  },
  {
    name: "Anoniem",
    feedback: "Nogmaals top concept, geheel passend in de wijk en tijdsgeest",
  },
  { name: "Andhika H.", feedback: "Great! ⭐️", type: "short" },
  { name: "Harm C.", feedback: "Goed! 👍", type: "short" },
  { name: "Tom A.", feedback: "Super 😁", type: "short" },
  {
    name: "Rik K.",
    feedback:
      "Top…. Swipen om te openen was even zoeken. Korte mini instructie van de belangrijkste functie van de auto zou handig zijn.",
  },
  { name: "Rolf E.", feedback: "Rit was prima! ", type: "short" },
  { name: "Marieke B.", feedback: "Dank voor de goede service" },
];
