import React from "react";
import Logo from "../../assets/logo/vloto.svg";
import Yes from "../../assets/icons/Checkbox.svg";
import No from "../../assets/icons/CheckboxCross.svg";
import Background from "../../assets/HeroComparisonBackground.svg";
import BackgroundMobile from "../../assets/HeroComparisonBackgroundMobile.svg";

export const HeroComparison = () => {
  return (
    <div className="relative w-full overflow-hidden rounded-[16px] bg-[#272F24] pl-5 text-white lg:h-[500px] lg:w-[548px] lg:rounded-[30px] lg:pl-8">
      <img
        src={Background}
        alt="Achtergrond"
        className="absolute right-[90px] hidden h-[464px] w-[100px] lg:right-[140px] lg:block lg:h-[500px] lg:w-[140px]"
      />
      <img
        src={BackgroundMobile}
        alt="Achtergrond"
        className="absolute right-[90px] block h-[464px] w-[100px] lg:right-[140px] lg:hidden lg:h-[500px] lg:w-[140px]"
      />
      <div className="mt-5 mr-3 mb-[18px] flex items-center justify-end space-x-6 lg:mb-[34px] lg:mt-[28px] lg:mr-[31px] lg:space-x-[64px]">
        <img src={Logo} alt="Vloto" />

        <p className="w-[67px] text-center text-[14px] leading-[22px] text-white lg:w-[78px] lg:text-[16px] lg:leading-[24px]">
          Andere deelauto's
        </p>
      </div>

      <div className="mb-[26px] flex flex-1 items-center lg:mb-[32px]">
        <div className="flex flex-1 flex-col">
          <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
            Luxe deelauto's
          </p>

          <p className="text-[14px] leading-[22px] text-[#A4A4A4] lg:text-[16px] lg:leading-[24px]">
            Mercedes, Polestar, Audi etc
          </p>
        </div>

        <div className="ml-2 flex w-[100px] items-center justify-center lg:w-[140px] ">
          <img src={Yes} alt="Vloto heeft het" className="z-10" />
        </div>

        <div className="z-20 flex w-[90px] items-center justify-center lg:w-[140px]">
          <img src={No} alt="Andere deelauto's hebben dit niet" />
        </div>
      </div>

      <div className="mb-[26px] flex flex-1 items-center lg:mb-[32px]">
        <div className="flex flex-1 flex-col">
          <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
            Geen reclame op de auto's
          </p>
        </div>

        <div className="ml-2 flex w-[100px] items-center justify-center lg:w-[140px] ">
          <img src={Yes} alt="Vloto heeft het" className="z-10" />
        </div>

        <div className="z-20 flex w-[90px] items-center justify-center lg:w-[140px]">
          <img src={No} alt="Andere deelauto's hebben dit niet" />
        </div>
      </div>

      <div className="mb-[26px] flex flex-1 items-center lg:mb-[32px]">
        <div className="flex flex-1 flex-col">
          <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
            Alleen voor jouw buurt
          </p>
        </div>

        <div className="ml-2 flex w-[100px] items-center justify-center lg:w-[140px] ">
          <img src={Yes} alt="Vloto heeft het" className="z-10" />
        </div>

        <div className="z-20 flex w-[90px] items-center justify-center lg:w-[140px]">
          <img src={No} alt="Andere deelauto's hebben dit niet" />
        </div>
      </div>

      <div className="mb-[26px] flex flex-1 items-center lg:mb-[32px]">
        <div className="flex flex-1 flex-col">
          <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
            Gratis uren
          </p>
        </div>

        <div className="ml-2 flex w-[100px] items-center justify-center lg:w-[140px] ">
          <img src={Yes} alt="Vloto heeft het" className="z-10" />
        </div>

        <div className="z-20 flex w-[90px] items-center justify-center lg:w-[140px]">
          <img src={No} alt="Andere deelauto's hebben dit niet" />
        </div>
      </div>

      <div className="mb-5 flex flex-1 items-center">
        <div className="flex flex-1 flex-col">
          <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
            Uurtarief
          </p>

          <p className="text-[14px] leading-[22px] text-[#A4A4A4] lg:text-[16px] lg:leading-[24px]">
            Pro abonnement
          </p>
        </div>

        <div className="ml-2 flex w-[100px] items-center justify-center lg:w-[140px] ">
          <div className="flex flex-col items-center">
            <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
              €2,50/uur
            </p>

            <p className="text-[14px] leading-[22px] text-[#A4A4A4] lg:text-[16px] lg:leading-[24px]">
              €0,26/km
            </p>
          </div>
        </div>

        <div className="z-20 flex w-[90px] items-center justify-center lg:w-[140px]">
          <div className="flex flex-col items-center">
            <p className="text-[14px] leading-[22px] text-white lg:text-[16px] lg:leading-[24px]">
              €3,71/uur
            </p>

            <p className="text-[14px] leading-[22px] text-[#A4A4A4] lg:text-[16px] lg:leading-[24px]">
              €0,35/km
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
