import React from "react";
import { BulletPointLineItem } from "../Custom/BulletPointLineItem";
import { BulletPointLineWrapper } from "../Custom/BulletPointLineWrapper";
import IJburgDelivery from "../../assets/photos/IJburgDelivery.png";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import DealLabel from "../DealLabel";
import { areas } from "../../utils/areas";

export const ExclusiveBanner = ({ showModal }) => {
  const navigate = useNavigate();

  const nonTaxiAreas = areas.filter(
    (area) => !area.isTaxi && area.name !== "Alle"
  );

  return (
    <div
      className="lg:tb-[60px] relative flex w-full flex-col items-start justify-start rounded-[16px] border-[1px] border-[#F4F4F4] bg-white 
     p-6 md:flex-row md:space-x-[53px] md:pb-[34px] md:pt-[34px] md:pl-[45px]  lg:mt-6 lg:w-[1120px] lg:max-w-[1120px] lg:self-center lg:overflow-hidden lg:rounded-[30px] lg:p-[50px]"
    >
      <div className=" flex w-full flex-col items-start">
        <h5 className="mb-4 text-start text-[24px] leading-[32px] md:w-[268px] md:text-[24px] lg:mb-6 lg:w-[433px] lg:max-w-[433px] lg:text-[32px] lg:leading-[40px]">
          Exclusief voor jouw community
        </h5>

        <p className="mb-6 leading-[24px] text-[#494949] md:w-[306px] lg:mb-10 lg:w-[447px] lg:max-w-[447px] lg:text-[20px] lg:leading-[32px]">
          Vloto is exclusief beschikbaar voor specifieke communities in
          Nederland. Woon jij in{" "}
          {nonTaxiAreas.map((area, index) => (
            <React.Fragment key={area.id}>
              {index === nonTaxiAreas.length - 1 && nonTaxiAreas.length > 1
                ? "of "
                : ""}
              <a
                className="cursor-pointer font-bold text-[#1CA556] underline"
                onClick={() => navigate(area.slug)}
              >
                {area.displayName}
              </a>
              {index < nonTaxiAreas.length - 1 ? ", " : "?"}
            </React.Fragment>
          ))}
          Dan kun jij profiteren van luxe deelauto’s voor een scherp tarief! Jij
          en je buren hebben toegang tot onze Audi e-tron 55 Quattro, Volkswagen
          ID.4, Polestar 2 en Mercedes EQB!
        </p>

        <div className="flex flex-col lg:flex-row lg:items-center">
          <div className="mb-2 w-[221px] lg:mb-0 lg:flex">
            <Button
              onClick={showModal}
              variant={"solid"}
              title={"Download de app"}
            />
          </div>
          <DealLabel />
        </div>
      </div>

      <img
        alt="Audi in de Krijgsman"
        src={IJburgDelivery}
        className="hidden object-none md:block  md:h-[438px] md:w-[235px] md:rounded-[24px] lg:h-[550px] lg:w-[397px]"
      />
    </div>
  );
};
